import React, { useState } from "react";
import './App.css';
import { useSearchParams } from "react-router-dom"; 
import dataSample from './DataTemplate.json';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from './PDF';

function MenuBtn({ name, func }) {
    return (
        <button className="menu-btn" onClick={func}>{name}</button>
    );
}

function NewRangeWindow(props) {
    const date = new Date();
    const dateCode = date.getFullYear().toString() + (date.getMonth() + 1).toString() + date.getDate().toString();

    const [, setSearchParam] = useSearchParams();

    const createRange = () => {
        // construct object
        const data = {
            name: name,
            house: housenum,
            house_per_range: amthouse,
            next_line: Number(nextline),
            row: rownum,
            row_per_house: rph,
            post: amtpost,
            pest_type: [
                {
                    name: "none",
                    color: "transparent"
                },
                {
                    name: "Aphids",
                    color:"rgb(255, 239, 98)"
                },
                {
                    name: "Thrips",
                    color:"rgb(98, 231, 255)"
                },
                {
                    name: "Spidermites",
                    color:"rgb(190, 98, 255)"
                },
                {
                    name: "Mildew",
                    color:"rgb(255, 192, 98)"
                },
                {
                    name: "Others",
                    color:"rgb(108, 255, 98)"
                }
            ],
            pest_data: []
        }
        
        for(let i=0; i < amthouse; i++) {
            let house = [] 
            
            for(let j = 0; j < rph; j++) {
                let row = []
                for(let k = 0; k < amtpost; k++) {
                    row.push([0]);
                }
                house.push(row)

            }
            data.pest_data.push(house);
        }

        // ush to url
        const urlParams = new URLSearchParams();
        urlParams.append('range', btoa(JSON.stringify(data)));
        setSearchParam(urlParams);

        /*const url = '?' + searchParams.toString();
        window.history.pushState({}, null, url);*/
        props.setVisible();
    }

    const [name, setName] = useState(dateCode);
    const handleName = ({ target }) => {
        setName(target.value);
    }

    const validateNumber = (value, setFunction) => {
        if(/^-?\d+$/.test(value) || value === '') {
            setFunction(value);
        }  
    }

    const [amthouse, setAmtHouse] = useState(0);
    const handleAmtHouse = ({ target }) => {
        validateNumber(target.value, setAmtHouse);
    }

    const [housenum, setHousenum] = useState(0);
    const handleHouseNum = ({ target }) => {
        validateNumber(target.value, setHousenum);
    }

    const [nextline, setNextLine] = useState(10);
    const handleNextLine = ({ target }) => {
        validateNumber(target.value, setNextLine);
        console.log(nextline);
    }

    const [rownum, setRownum] = useState(0);
    const handleRowNum = ({ target }) => {
        validateNumber(target.value, setRownum);
    }

    const [rph, setRph] = useState(5);
    const handleRPH = ({ target }) => {
        validateNumber(target.value, setRph);
    }

    const [amtpost, setAmtpost] = useState(21);
    const handleAmtPost = ({ target }) => {
        validateNumber(target.value, setAmtpost);
    }

    return (
        <div className={`window-bg ${props.visible ? 'vis' : 'invis'}`}>
            <div className="new window container">
                <h1>New Range</h1>
                <p><strong>WARNING: This action will override previous values. Make sure you save!<br /></strong></p>
                
                <label htmlFor="name">Name</label>
                <input id="name" type="text" value={name} onChange={handleName}></input>

                <label htmlFor="amthouse">Amount of Houses</label>
                <input id="amthouse" type="text" inputMode="numeric" value={amthouse} onChange={handleAmtHouse} min="0"></input>

                <label htmlFor="housenum">House Number Start</label>
                <input id="housenum" type="text" inputMode="numeric" value={housenum} onChange={handleHouseNum} min="0"></input>

                <label htmlFor="nextline">Houses In Each Line</label>
                <input id="nextline" type="text" inputMode="numeric" value={nextline} onChange={handleNextLine} min="1"></input>

                <label htmlFor="rownum">Row Number Start</label>
                <input id="rownum" type="text" inputMode="numeric" value={rownum} onChange={handleRowNum}></input>

                <label htmlFor="rph">Row Per House</label>
                <input id="rph" type="text" inputMode="numeric" value={rph} onChange={handleRPH}></input>

                <label htmlFor="amtpost">Amount of Post</label>
                <input id="amtpost" type="text" inputMode="numeric" value={amtpost} onChange={handleAmtPost}></input>

                <div>
                    <button onClick={createRange}>Create</button>
                    <button onClick={props.setVisible}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

function LoadFileWindow(props) {
    const [, setSearchParam] = useSearchParams();
    const [file, setFile] = useState(dataSample);

    const handleInput = ({ target }) => {
        setFile(target.files[0])
        console.log(target.files[0]);
    }

    const handleLoad = () => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
            const urlParams = new URLSearchParams();
            if(props.isFullLoad) { urlParams.append('range', btoa(reader.result)) }
            else {

                //const originData = JSON.parse(atob(searchParam.get('range')));
                const newData = JSON.parse(reader.result);
                // create new range, resetting data
                const data = {
                    name: newData.name,
                    house: newData.house,
                    house_per_range: newData.house_per_range,
                    row: newData.row,
                    row_per_house: newData.row_per_house,
                    post: newData.post,
                    pest_type: [newData.pest_type],
                    pest_data: []
                }
                // fill in pest_data
                for(let i=0; i < newData.house_per_range * newData.row_per_house * newData.post; i++) { data.pest_data.push([0]) }
                // push to URL
                urlParams.append('range', btoa(JSON.stringify(data)));
                console.log(data);
            }
            setSearchParam(urlParams);
            props.setVisible();
        };
    }

    return (
        <div className={`window-bg ${props.visible ? 'vis' : 'invis'}`}>
            <div className="window container">
                {props.isFullLoad ? <h1>Load Range</h1> : <h1>Load Range Settings</h1>}
                <p><strong>WARNING: This action will override previous values. Make sure you save!<br /></strong></p>
                <input type="file" id="file" className="button" accept="application/json" onChange={handleInput} ></input>
                <button onClick={handleLoad}>Load</button>
                <button onClick={props.setVisible}>Cancel</button>
            </div>
        </div>
    )
}

function ExportWindow(props) {
    const searchParams = new URLSearchParams(window.location.search);
    const data = searchParams.get('range') !== null ? JSON.parse(atob(searchParams.get('range'))) : dataSample;
    console.log(JSON.stringify(data));

    return (
        <div className={`window-bg ${props.visible ? 'vis' : 'invis'}`}>
        <div className="window container">
            <h1>Export Range</h1>
            
            <div>
                <h2>PDF</h2>
                <div className="button"><PDFDownloadLink document={<PDF data={data}/>} fileName={data.name}>Download</PDFDownloadLink></div>
            </div>

            <button onClick={props.setVisible}>Close</button>
        </div>
    </div>
    );
}

function Menu({ isMenu }) {
    const [isRangeWindow, setIsRangeWindow] = useState(false);
    const rangeBtn = () => {
        setIsRangeWindow(isRangeWindow ? false : true);
    }

    const [isLoadWindow, setIsLoadWindow] = useState(false);
    const loadBtn = () => {
        setIsLoadWindow(isLoadWindow ? false : true);
    }

    const [isLoadSettingsWindow, setIsLoadSettingsWindow] = useState(false);
    const loadSettingsBtn = () => {
        setIsLoadSettingsWindow(isLoadSettingsWindow ? false : true);
    }

    const[isExportWindow, setIsExportWindow] = useState(false);
    const exportBtn = () => {
        setIsExportWindow(isExportWindow ? false : true);
    }

    const HelloWorld = () => {
        console.log('Hello, World!');
    };

    const handleSave = () => {
        console.log('Hello World');
        const a = document.createElement('a');
        const searchParams = new URLSearchParams(window.location.search);
        const data = searchParams.get('range') !== null ? JSON.parse(atob(searchParams.get('range'))) : dataSample;
        const file = new Blob([JSON.stringify(data)], { type: "application/json"})
        a.href = URL.createObjectURL(file);
        a.download = data.name;
        a.click();
    }

    return (
        <div 
            id="sidebar" 
            style={{transform: `translateX(${isMenu ? '0' : '-100%'})`}}>
            <h1>Menu</h1>
            <ul>
                <li>
                    <MenuBtn name="New Range" func={rangeBtn} />
                    <NewRangeWindow visible={isRangeWindow} setVisible={rangeBtn} />
                </li>
                <li>
                    <MenuBtn name="Load Range Settings" func={loadSettingsBtn} />
                    <LoadFileWindow visible={isLoadSettingsWindow} setVisible={loadSettingsBtn} isFullLoad={false} />
                </li>
                <li><MenuBtn name="Save Range" func={handleSave} /></li>
                <li>
                    <MenuBtn name="Load Range" func={loadBtn} />
                    <LoadFileWindow visible={isLoadWindow} setVisible={loadBtn} isFullLoad={true} />
                </li>
                <li>
                    <MenuBtn name="Export Range" func={exportBtn} />
                    <ExportWindow visible={isExportWindow} setVisible={exportBtn}/>
                </li>
                <li><MenuBtn name="Settings" func={HelloWorld} /></li>
            </ul>
      </div>
    )
}

export default Menu;