import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontSize: 11
  },
  section: {
    flexDirection: 'column',
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  heading: {
    fontSize: 25,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center'
  },
  table: {
    flexDirection: 'row'
  },
  tableColumn: {
    border: '1px solid black',
    Width: 30,
    Height: 30
  },
  post: { 
    display: 'flex', 
    flexDirection: 'row', 
    width: '20px', 
    height: '20px'
  },
  pestLegend: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: 150
  },
  pestType: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  pestColorPalette: {
    width: 20,
    height: 15,
  },
  pestName: {
    marginLeft: 10
  }

});

// Create Document Component
const PDF = (props) => {
  const createTable = () => {
    // for each house
    return props.data.pest_data.map((house) => {
      let postColumn = [];

      // creating an unique array of pest codes
      for(let post = 0; post < props.data.post; post++) {
        let postData = new Set();
        for(let row = 0; row < props.data.row_per_house; row++) {
          house[row][post].forEach((each) => {
            postData.add(each);
          })
          
        }

        let cells = []
        // Step - remove 0's

        if(postData.size > 1) postData.delete(0);

        // inside each cell
        postData.forEach((pest) => {
          cells.push(<View style={{ backgroundColor: props.data.pest_type[pest].color, flex: '1'}}></View>)
        })

        postColumn.push(<View style={styles.post}>{cells}</View>)
      }

      // column containing cells
      return <View style={styles.tableColumn}>{postColumn}</View>
    });
  };

  return (
    <Document>
      <Page size='LETTER' style={styles.page} orientation='landscape'>
        <View style={styles.section}>
          <Text style={styles.heading}>{props.data.name}</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.table}>
            {
              createTable()
            }
          </View>
        </View>
        <View style={styles.section}><View style={styles.pestLegend}>
          {props.data.pest_type.map((type) => (
            <View style={styles.pestType}>
              <View style={{ backgroundColor: `${type.color}` }}><View style={styles.pestColorPalette}></View></View>
              <Text style={styles.pestName}>{type.name}</Text>
            </View>
          ))}
        </View></View>
      </Page>
    </Document>
  )
};

export default PDF;