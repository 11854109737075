import React from 'react';
import './App.css';
import { useState } from 'react';

import Menu from './Menu.js';
import Main from './Main.js';
import PostWindow from './PostWindow.js';

function App() {
  const [isMenu, setIsMenu] = useState(false);

  const handleMenuClick = () => {
    setIsMenu(isMenu ? false : true);
  };

  const [isPostWindow, setIsPostWindow] = useState(false);

  const inVisible = () => { setIsPostWindow(false) };
  const visible = () => { setIsPostWindow(true) };

  return (
    <div className="App">
      <Menu isMenu={isMenu} />
      <button onClick={handleMenuClick} id="open-menu" style={{transform: `rotate(${isMenu ? '0.5turn' : '0'})`}}></button>
      <Main toggleVisible={visible}/>
      <PostWindow visible={isPostWindow} setVisible={inVisible}/>
      {/*<PDFDownloadLink className='button' document={<PDF />} fileName='PDF'>Download</PDFDownloadLink>
      <PDFViewer style={{width: '100vw', height: '100vh'}}><PDF /></PDFViewer>*/}

    </div>
  );
}

export default App;
