import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import './App.css';

import dataSample from './DataTemplate.json';

function Post({ number, index, data, toggleVisible }) {
    const handlePostClick = () => {
        sessionStorage.setItem('houseIndex', index[0]);
        sessionStorage.setItem('rowIndex', index[1]);
        sessionStorage.setItem('postIndex', index[2]);
        toggleVisible();
    }

    return (
        <>
            <button className="post-btn" onClick={handlePostClick}>
                {data.map((pest, index) => (
                    <div key={`h-${index[0]}-r-${index[1]}-p-${index[2]}`} className="button" style={{ backgroundColor: dataSample.pest_type[pest].color }} />
                ))}
                {number}
            </button>
        </>
    );
}

function Row({ number = 0 }) {
    return (
        <div className="row cell">{number}</div>
    );
}

function Main(props) {

    // updating data upon URL change
    const [data, setData] = useState(dataSample);

    let location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setData(searchParams.get('range') !== null ? JSON.parse(atob(searchParams.get('range'))) : dataSample);
    }, [location]);

    const createTable = (input) => {


        // for number of house in a line create a JSX array of houses
        // const numberOfLines = Math.ceil(input.house_per_range / input.next_line);

        let JSX = [];

        let line = [];

        input.pest_data.forEach((house, houseIndex) => {
            // construct house
            line.push(<div key={houseIndex} ><div className="house-header">{Number(data.house) + houseIndex}</div><div className="house">
                {house.map((row, rowIndex) => (
                    <div key={houseIndex} className="row">
                        <div className="row-header"><Row number={Number(data.row) + rowIndex} /></div>
                        {row.map((post, postIndex) => (
                            <div className="post" key={postIndex} ><Post number={postIndex + 1} index={[houseIndex, rowIndex, postIndex]} data={post} toggleVisible={props.toggleVisible} /></div>
                        ))}
                    </div>
                ))}
            </div></div>)

            // if house reaches the maximum per line
            if((houseIndex + 1) % input.next_line === 0 || houseIndex === input.house_per_range - 1) {
                JSX.push(<div key={'h'} className="table">{line}</div>);
                console.log(JSX);
                line = [];
            }
        });

        /*for(let h = 0; h < input.house_per_range; h = h + input.next_line) {
            console.log(`h: ${h}`)
            let line = [];
            // for each house create a JSX array
            for(let i = 0; i < input.next_line && h + i < input.house_per_range; i++) {
                let house = [];



                // for each row create a JSX array
                for(let j = 0; j < input.row_per_house; j++) {
                    let row = [<div className="row-header"><Row number={Number(data.row) + i * input.row_per_house + j}/></div>];
                    
                    // for each post push JSX element
                    for(let l = 0; l < input.post; l++) {
                        const currentIndex = (h + i) * input.row_per_house * input.post + j * input.post + l;
                        console.log(currentIndex)
                        row.push(
                            <div className="post" key={l} ><Post number={l + 1} index={currentIndex} data={data} toggleVisible={props.toggleVisible} /></div>
                        )
                    }
                    house.push(<div key={j} className="row">{row}</div>);
                }
                
                line.push(<div key={i} ><div className="house-header">{Number(data.house) + i}</div><div className="house">{house}</div></div>);
            }
            JSX.push(<div key={h} className="table">{line}</div>);
        }*/


        return JSX;
    }

    return (
        <>
            <h1>IPM MAPPER</h1>
            <section id="dataManipulation">
                <div className="element">
                    {createTable(data)}
                </div>
            </section>
        </>
    )
}

export default Main;