import React from "react";
import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import './App.css';

import dataSample from './DataTemplate.json';

function PostWindow(props) {

    // default setting
    useEffect(() => {
        sessionStorage.setItem('houseIndex', 0);
        sessionStorage.setItem('rowIndex', 0);
        sessionStorage.setItem('postIndex', 0);
    }, []);

    // update when data updated
    const [data, setData] = useState(dataSample);
    // update when visibility updated
    const [visible, setVisible] = useState(props.visible);

    // the session checkbox data
    const [ userSelection, setUserSelection ] = useState([false, false, false, false, false, false]);

    // look for visibility updates
    useEffect(() => {
        setVisible(props.visible);
        const searchParams = new URLSearchParams(window.location.search);
        setData(searchParams.get('range') !== null ? JSON.parse(atob(searchParams.get('range'))) : dataSample);
    }, [props.visible]);

    // sync userSelection
    useEffect(() => {
        setUserSelection(data.pest_type.map((pest, code) => {
            return data.pest_data[sessionStorage.getItem('houseIndex')][sessionStorage.getItem('rowIndex')][sessionStorage.getItem('postIndex')].includes(code);
        }));
    }, [data])

    const handleClick = ({ target }) => {
        // create a new array and apply to userSelection
        setUserSelection(userSelection => userSelection.map((pest, index) => {
            if(index === Number(target.value)) {
                // if the current element index is the target pest index
                return target.checked;
                
            } else {
                return pest;
                
            }
        }));
    }

    const [, setSearchParam] = useSearchParams();

    const handleSave = () => {
        // get data
        const searchParams = new URLSearchParams(window.location.search);
        let data = (searchParams.get('range') !== null ? JSON.parse(atob(searchParams.get('range'))) : dataSample);

        // set data
        const post = [];
        userSelection.forEach((pest, index) => {
            if(pest && index !== 0) {
                post.push(index);
            }
        });
        if(post.length === 0) {
            post.push(0);
        }
        data.pest_data[sessionStorage.getItem('houseIndex')][sessionStorage.getItem('rowIndex')][sessionStorage.getItem('postIndex')] = post;
        
        // push data
        const urlParams = new URLSearchParams();
        urlParams.append('range', btoa(JSON.stringify(data)));
        setSearchParam(urlParams);

        props.setVisible();
    };

    return (
        <div className={`window-bg ${visible ? 'vis' : 'invis'}`}>
            <div className="window container">
                <h1>Edit Post</h1>
                <div className="checkboxes">
                    {data.pest_type.map((type, index) => {
                        if(index > 0) {
                            return (
                                <label key={index} className="checkbox" >
                                    {type.name} <input 
                                        type="checkbox" 
                                        id={index} 
                                        name={index} 
                                        value={index} 
                                        checked={userSelection[index]}
                                        onClick={handleClick} />
                                </label>
                            );
                        } else {
                            return(<></>);
                        }
                    })}
                </div>
                <div>
                    <input key={'save'} type="button" value="Save" className="button" onClick={handleSave} />
                    <input key={'cancel'} type="button" value="Cancel" className="button" onClick={props.setVisible}/>
                </div>
            </div>  
        </div>
    )
}

export default PostWindow;